<script>
export default {
  name: 'user',
  data() {
    return {
      value: ""
    }
  }
}
</script>

<template>
  <div>
    <h1 v-if="$route.params.id">other index userId: {{ $route.params.id }}</h1>
    <h1 v-else>my index</h1>
    <input type="text" v-model="value">
    <RouterLink to="/user/2">User 2</RouterLink>
  </div>
</template>